<template>
    <layout name="OrganizLayout">
        <div class="Home">
            <page-header/>
            <section>
                <div class="uk-container uk-container-small uk-margin-bottom">
                    <div class="uk-text-center uk-margin">
                        <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                            <li>
                                <a>{{$t("organization.stuReg.home")}}</a>
                            </li>
                            <li>
                                <span>{{$t("organization.stuReg.sReg")}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="uk-width-1-1 uk-width-expand@m">
                        <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                            <form @submit.prevent="save" method="post" class="uk-form-stacked">
                                <header class="uk-card-header uk-text-center"><h1 class="uk-h3">
                                    {{$t("organization.stuReg.sReg")}}</h1></header>
                                <div class="uk-card-body">
                                    <div v-if="error!==null" class="uk-alert-warning" uk-alert>
                                        <p v-html="error" class="uk-text-center"></p>
                                    </div>
                                    <div class="uk-grid-medium uk-child-width-1-2" uk-grid>
                                        <fieldset class="uk-fieldset uk-width-1-1 uk-text-center">
                                            <!--<legend class="uk-h4">{{$t("organization.profReg.pImage")}}</legend>-->
                                            <div>
                                                <section>
                                                    <div uk-form-custom
                                                         class="js-upload uk-margin-auto uk-visible-toggle u
                                                         k-position-relative uk-border-circle uk-overflow-hidden uk-light">
                                                        <input type="file" id="file" ref="file" @change="previewImage"
                                                               accept="image/*"
                                                               style="font-size: 0px;">

                                                        <div v-if="imageData.length == 0">
                                                            <img class="uk-padding-small" tabindex="-1" width="150"
                                                                 src="../../assets/user.png">
                                                        </div>
                                                        <div v-else>
                                                            <img class="uk-padding-small" tabindex="-1" width="150"
                                                                 :src="imageData">
                                                        </div>

                                                        <a class="uk-link-reset uk-overlay-primary uk-position-cover uk-hidden-hover"
                                                                href="#">
                                                            <div class="uk-position-center"><span
                                                                    uk-icon="icon: camera; ratio: 1.25;"></span></div>
                                                        </a>
                                                    </div>
                                                </section>
                                            </div>
                                        </fieldset>
                                        <fieldset class="uk-fieldset uk-width-1-1">
                                            <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" uk-grid>
                                                <div>
                                                    <label>
                                                        <div class="uk-form-label">{{$t("organization.profReg.fName")}}
                                                            <span style="color: red">*</span>
                                                        </div>
                                                        <input v-model="firstname" class="uk-input" type="text"/>
                                                    </label>
                                                </div>
                                                <div>
                                                  <label>
                                                    <div class="uk-form-label">{{ $t("ID") }}
                                                      <span style="color: red">*</span></div>
                                                    <input v-if="$route.params.id == '0'" class="uk-input" type="text" v-model="username"/>
                                                    <input v-else disabled class="uk-input" type="text" v-model="username"/>
                                                  </label>
                                                </div>
                                                <!--<div>-->
                                                <!--<label>-->
                                                <!--<div class="uk-form-label">{{$t("organization.profReg.lname")}}-->
                                                <!--<span style="color: red">*</span></div>-->
                                                <!--<input class="uk-input" type="text" v-model="lastname"/>-->
                                                <!--</label>-->
                                                <!--</div>-->
                                                <div>
                                                    <label>
                                                        <div class="uk-form-label">{{$t("organization.profReg.email")}}
                                                            <span style="color: red">*</span></div>
                                                        <input v-if="$route.params.id == '0'"  class="uk-input" type="email" v-model="email"/>
                                                        <input v-else disabled class="uk-input" type="text" v-model="email"/>
                                                    </label>
                                                </div>
                                              <div v-if="this.$route.params.id == 0">
                                                <label>
                                                  <div class="uk-form-label">{{$t("regpass")}}
                                                    <span style="color: red">*</span></div>
                                                  <input v-if="$route.params.id == '0'" class="uk-input"
                                                         type="password" v-model="password"/>
                                                  <input v-else disabled class="uk-input" type="password"
                                                         v-model="password"/>
                                                </label>
                                              </div>
                                                <div><label>
                                                  <div class="uk-form-label">{{$t("organization.profReg.gender")}}</div>
                                                  <select v-model="gender" class="uk-select">
                                                    <option value="man">{{$t("organization.profReg.man")}}</option>
                                                    <option value="women">{{$t("organization.profReg.women")}}
                                                    </option>
                                                  </select></label></div>

                                                <div><label>
                                                    <div class="uk-form-label">{{$t("organization.profReg.pNumber")}}</div>
                                                    <input class="uk-input" type="tel" v-model="phone"/></label>
                                                </div>
                                                <div><label>
                                                    <div class="uk-form-label">{{$t("organization.profReg.dBirth")}}
                                                    </div>
                                                    <input class="uk-input uk-width-1-1" type="date" id="start" name="trip-start"
                                                           v-model="birthday"
                                                           :lang="lang"
                                                           :placeholder="$t('birthDate')"/>
                                                    <!--<date-picker-->
                                                            <!--class="date-picker uk-width-1-1"-->
                                                            <!--v-model="birthday"-->
                                                            <!--:placeholder="$t('birthDate')"-->
                                                            <!--:lang="lang"-->
                                                    <!--&gt;</date-picker>-->
                                                </label></div>

                                              <div>
                                                <label>
                                                  <div class="uk-form-label">{{ $t("department") }}</div>
                                                  <input class="uk-input" type="text"
                                                         v-model="department"/></label>
                                              </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <input type="hidden" id="submitbutton"/>

                                </div>
                                <!-- v-if="this.userId > 0" -->
                                <div class="uk-card-footer uk-text-center" >
                                  <button type="button"  @click="passwordNew()"
                                          v-if="this.$route.params.id != 0"
                                          v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'"
                                          class="uk-button uk-button-default uk-margin-right">{{ $t("changePass") }}
                                  </button>
                                  <button type="submit" class="uk-button uk-button-primary"
                                          v-if="this.$route.params.id == 0">{{ $t("save") }}</button>
                                  <button type="button"  @click="userUpdate()"  v-else
                                          class="uk-button uk-button-primary">{{$t("organization.stuReg.update")}}
                                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
              <div id="passModel" uk-modal>
                <div class="uk-modal-dialog">
                  <button class="uk-modal-close-default"  uk-close></button>
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center"
                        style="font-size: 21px; font-weight: 700;">{{ $t("uchaPass") }}</h2>
                  </div>
                  <div class="uk-modal-body uk-text-left">
                    <h3>{{ $t("confirmQPass") }}</h3><br>
                    <div class="uk-flex">
                      <input type="pass" class="uk-input" v-model="password" v-if="showPass == true">
                      <input type="text" class="uk-input" v-model="password" v-else>
                      <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-left"
                              @click="password = ''" style="min-width: 80px;">{{ $t("clear") }}</button>
                      <button type="button" class="uk-button uk-button-default uk-button-small uk-margin-left"
                              @click="showPass = !showPass" style="min-width: 80px;">
                        <span v-if="showPass == true">{{ $t("show") }}</span>
                        <span v-else>{{ $t("hide") }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="uk-modal-footer uk-text-right">
                    <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">
                      {{ $t("close") }}
                    </button>
                    <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                            @click="userUpdate()">{{ $t("navMenu.save") }}
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <page-footer/>
        </div>
    </layout>
</template>

<script scoped>
    import Layout from '../../layouts/Layout'
    import UserService from '@/services/UserService'
    import {mapGetters} from 'vuex'
    import UIkit from 'uikit'
    // import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';
    import CategoryDataService from "@/services/CategoryDataService"
    import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
    const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");
    export default {
        name: 'Home',
        components: {
            Layout,
            // DatePicker,
            PageHeader,
            PageFooter
        },
        data() {
            return {
                showPass: true,
                firstname: '',
                lastname: '',
                phone: '',
                birthday: '',
                country: '',
                city: '',
                postcode: '',
                street: '',
                doornumber: '',
                gender: '',
                password: '',
                grade:'',
                department:'',
                email: '',
                username:'',
                error: null,
                cate1: '0',
                cate2: '0',
                cate3: '0',
                mainCategory: {
                    main: [],
                    middle: [],
                    low: []
                },
                imageData: "",
                file: null,
                lang: {
                    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    months: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                },
                shortcuts: [
                    {
                        text: "Today",
                        onClick: () => {
                            this.time3 = [new Date()];
                        }
                    }
                ],
                timePickerOptions: {
                    start: "00:00",
                    step: "00:30",
                    end: "23:30"
                },
                userDetail: []
            }
        },

        created() {
            this.userId = this.$route.params.id
        },
        async mounted() {
            this.loadCates("main", 1, 0);
            if (this.$route.params.id) {
                if (this.$route.params.id > 0) {
                    this.userDetails(this.$route.params.id)
                }
            }
        },
        methods: {
          passwordNew(){
            UIkit.modal('#passModel').show();
          },
            previewImage: function (event) {
                this.file = this.$refs.file.files[0];
                // Reference to the DOM input element
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.imageData = e.target.result;
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
                }
            },
            async userDetails(uId) {
                try {
                    const response = await UserService.userDetails(uId);
                    let userDetail = response.data;
                    console.log(response)
                    this.firstname = userDetail.firstName;
                    this.lastname = userDetail.lastName;
                    this.phone = userDetail.phone;
                    this.birthday = userDetail.birth;
                    this.gender = userDetail.gender;
                    this.email = userDetail.email;
                    this.username = userDetail.username;
                    this.password = userDetail.password;
                    this.department = userDetail.department;
                    this.grade = userDetail.grade;

                    if (userDetail.image != null)
                        if (userDetail.image.length > 0) {
                            this.imageData = '/uploadingDir/' + userDetail.image;
                        }

                } catch (error) {
                    console.log(error)
                }
            },
            async userUpdate() {
                try {
                    if (this.firstname.length == 0) {
                        alert("Please write first name.");
                        return;
                    }

                    let formData = new FormData();

                    formData.append('userId', this.$route.params.id);
                    formData.append('firstname', this.firstname);
                    formData.append('username', this.username);
                    formData.append('lastname', this.lastname);
                    formData.append('phone', this.phone);
                    formData.append('birthday', this.birthday);
                    formData.append('country', this.country);
                    formData.append('city', this.city);
                    formData.append('postcode', this.postcode);
                    formData.append('street', this.street);
                    formData.append('doornumber', this.doornumber);
                    formData.append('gender', this.gender);
                    formData.append('password', this.password);
                    formData.append('department', this.department);
                    formData.append('grade', this.grade);
                    formData.append('email', this.email);
                    formData.append('organizCode', this.$store.getters.currentUser.organizs[0].organizId);
                    formData.append('type', 'student');
                    formData.append('cate1', this.cate1);
                    formData.append('cate2', this.cate2);
                    formData.append('cate3', this.cate3);

                    if (this.file)
                        formData.append('image', this.file);
                    const response = await UserService.userModify(formData);

                    if (response.data.status == 200) {
                        if (response.data.success)
                            UIkit.modal.alert(response.data.message).then(function () {
                                console.log('Alert closed.')
                            });
                        this.$router.push({name: 'studentlist'})

                    }

                } catch (error) {
                    this.error = error.response.data.message;
                }
            },

            async save() {
                try {
                    if (this.firstname.length == 0) {
                        alert("Please write first name.");
                        return;
                    }
                    if (this.email.length == 0) {
                        alert("Please write email address.");
                        return;
                    }
                    if (this.password.length == 0) {
                        alert("Please enter password.");
                        return;
                    }
                    let formData = new FormData();

                    formData.append('firstname', this.firstname);
                    formData.append('username', this.username);
                    formData.append('lastname', this.lastname);
                    formData.append('phone', this.phone);
                    formData.append('birthday', this.birthday);
                    formData.append('country', this.country);
                    formData.append('city', this.city);
                    formData.append('postcode', this.postcode);
                    formData.append('street', this.street);
                    formData.append('doornumber', this.doornumber);
                    formData.append('gender', this.gender);
                    formData.append('password', this.password);
                    formData.append('department', this.department);
                    formData.append('grade', this.grade);
                    formData.append('email', this.email);
                    formData.append('organizCode', this.$store.getters.currentUser.organizs[0].organizId);
                    formData.append('type', 'student');
                    formData.append('cate1', this.cate1);
                    formData.append('cate2', this.cate2);
                    formData.append('cate3', this.cate3);

                    if (this.file)
                        formData.append('image', this.file);


                    const response = await UserService.registerUser(formData);

                    if (response.data.success) {
                        UIkit.modal.alert(response.data.message).then(function () {
                            console.log('Alert closed.')
                        });
                        this.$router.push({name: 'studentlist'})
                    }


                } catch (error) {
                    console.log(error);
                    this.error = error.response.data.message;
                    this.scrollToTop()
                }

            },
            scrollToTop() {
                window.scrollTo(0, 0)
            },
            categoryChange(type, catelevel) {
                if (type == "exam") {
                    type = "main";
                }

                if (catelevel == "main") {
                    let parentId = 0;
                    if (type == "main") parentId = this.cate1;
                    else parentId = this.search.scate1;
                    this.loadCates(type, 2, parentId);
                }
                if (catelevel == "middle") {
                    let parentId = 0;
                    if (type == "main") parentId = this.cate2;
                    else parentId = this.search.scate2;
                    this.loadCates(type, 3, parentId);
                }
            },
            async loadCates(type, cateStep, parentId) {
                try {
                    const response = await CategoryDataService.getCategoriesStep({
                        type: type,
                        cateStep: cateStep,
                        parentId: parentId
                    });
                    if (response.data.status === 200) {
                        if (type == "main") {
                            //Category
                            if (cateStep == 1) {
                                this.mainCategory.main = response.data.result.cates;
                            }
                            if (cateStep == 2) {
                                this.mainCategory.middle = response.data.result.cates;
                            }
                            if (cateStep == 3) {
                                this.mainCategory.low = response.data.result.cates;
                            }
                        }
                    } else {
                        alert("ERROR: " + response.data.message);
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            // loadKeywordData (datatype, keyword) {
            //   AnswersService.getAnswersByPage({
            //     mtype: datatype,
            //     keyword: keyword,
            //     size: 35
            //   }).then(res => {
            //     const { data } = res
            //     this.filtereddatas = data.content
            //     if (!this.filtereddatas.length) {
            //       return
            //     }
            //   })
            // },
        },
        computed: {
          ...mapGetters(['isLoggedIn', 'currentUser'])
        }
    }
</script>

<style scoped>
    .file-upload-form, .image-preview {
        padding: 20px;
    }

    .uk-card-body {
        padding: 20px 30px;
    }

    .uk-child-width-1-1 {
        padding-left: 25px;
        padding-right: 25px;
    }
    img.preview {
        width: 200px;
        background-color: white;
        border: 1px solid #DDD;
        padding: 5px;
    }
    input[type="pass"]
    {
      -webkit-text-security: disc;
    }
</style>
